@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body,
#root {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

#root {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

// Blue: #2A4D9F, rgb(50,50,150)
// Orange: rgb(255,100,0), rgb(255,100,0)